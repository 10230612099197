import React from "react";

import "./ProjectsPage.css";

const ProjectsPage = (props) => {
  return (
    <div className="projects-page">
      <p>
        I'm still working on this page before it gets deployed for the first
        time.
      </p>
      <hr />
      <p>
        In the meantime, you can check out{" "}
        <a
          href="https://github.com/cyamonide"
          target="_blank"
          rel="noopener noreferrer"
        >
          my GitHub profile
        </a>{" "}
        to see what I've been up to there. Come back later!
      </p>
      <hr />
      <p>
        <i>Last updated October 2021.</i>
      </p>
    </div>
  );
};

export default ProjectsPage;
