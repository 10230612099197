import React from "react";
import { Grid, Link } from "@material-ui/core";

// import { BusinessCard, SocialCrumbs, RandomQuote } from "../../components";
import mugshot from "./mugshot.jpg";
import "./HomePage.css";

const MILLIS_TO_YEARS = 1000 * 60 * 60 * 24 * 365;

const HomePage = (props) => {
  const yearsAtCoursera = (Date.now() - Date.UTC(2020, 1, 6)) / MILLIS_TO_YEARS;
  return (
    <>
      <div className="home-page-wrapper">
        <div>
          <Grid container justify="flex-start">
            <Grid item id="mugshot">
              <img src={mugshot} alt="mugshot" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <h1>
                SIMON LIU <span className="subtle">{"// cyamonide"}</span>
              </h1>
            </Grid>
          </Grid>
        </div>
        <div className="home-page-card">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={5}>
              <h2>Contact</h2>
            </Grid>
            <Grid item xs={7}>
              <p>
                <Link href="mailto:hello@cyamonide.ca">hello@cyamonide.ca</Link>
              </p>
            </Grid>
          </Grid>
        </div>
        <div className="home-page-card">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={5}>
              <h2>Education</h2>
            </Grid>
            <Grid item xs={7}>
              <p>
                Computer Science <span className="subtle">at</span>
              </p>
              <p>University of Waterloo</p>
              <p>
                <span className="subtle">Class of 2022.</span>
              </p>
            </Grid>
          </Grid>
        </div>
        <div className="home-page-card">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={5}>
              <h2>Work</h2>
            </Grid>
            <Grid item xs={7}>
              <p>
                Software Engineer <span className="subtle">working on</span>
              </p>
              <p>
                Data Infrastructure <span className="subtle">at</span>
              </p>
              <p>Coursera</p>
              <span className="subtle">
                I've been working here for {yearsAtCoursera.toFixed(3)} years.
              </span>
            </Grid>
          </Grid>
        </div>
        <div className="home-page-card">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={5}>
              <h2>Interests</h2>
            </Grid>
            <Grid item xs={7}>
              <p>Mountain biking</p>
              <p>Bouldering</p>
              <p>Formula 1</p>
              <p>Mechanical keyboards</p>
            </Grid>
          </Grid>
        </div>
        <div className="home-page-card">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={5}>
              <h2>Social</h2>
            </Grid>
            <Grid item xs={7}>
              <p>
                <Link href="https://www.linkedin.com/in/cyamonide/">
                  LinkedIn
                </Link>
              </p>
              <p>
                <Link href="http://github.com/cyamonide/">GitHub</Link>
              </p>
              <p>
                <Link href="https://www.strava.com/athletes/cyamonide">
                  Strava
                </Link>
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default HomePage;
