import React from "react";

import "./AchievementsPage.css";

const AchievementsPage = (props) => {
  return (
    <div class="achievements-page">
      <p>
        I'm still working on this page before it gets deployed for the first
        time.
      </p>
      <hr />
      <p>
        Since you're here, I can tell you that I enjoy competitive programming,
        and have participated in many contests. Each contest below gets its own
        little anecdote, but I've bolded the key parts for those in a hurry.
      </p>
      <p>
        <b>Google CodeJam</b>
        <ul>
          <li>
            2019 - My first year writing CodeJam. I scored poorly on both Round
            1A and 1B, so 1C was my last chance to qualify for the next round. I
            had to wake up at 5AM to write it and, by some stroke of luck, I
            placed <b>593 out of over 6,500 contestants</b>, qualifying for{" "}
            <b>Round 2</b>.
            <br />
            <br />
            Round 2 was very challenging. Nothing to write home about Maybe next
            year.
            <br />
            <br />
          </li>
          <li>
            2020 - I wasn't able to write this year, since life got really busy
            as I had to fly back home to Toronto from Mountain View and finish
            the rest of my internship with Coursera remote (thanks COVID).
            <br />
            <br />
          </li>
          <li>
            2021 - I performed well on Round 1A, and had an unofficial ranking
            of <b>537 out of 10,000+ competitors</b> at the end of regulation
            time.
            <br />
            <br />
            Although this was my best performance to date, I was soon
            disqualified after pushing my solution to a GitHub repository where
            I archived all of my competitive programming work, quickly finding
            out that my repo was public, and somebody (or something) perused the
            web to discover my exact solution, line for line, publicly
            available. Oops.
            <br />
            <br />
            That repository of mine is called <code>the-vault</code>, and you
            can be sure that it's private today. I will admit that this is a
            rather embarrassing story, but lesson learned on my part. If you're
            reading this, I hope this doesn't happen to you :)
          </li>
        </ul>
      </p>
      <p>
        <b>USA Computing Olympiad (USACO)</b>
        <ul>
          <li>
            2017 December Contest - This was my first time writing a USACO
            contest after a friend recommended I try it. I first competed in the
            (lowest) Bronze division and scored 1000/1000, after which I was
            promoted to Silver for the same contest.
            <br />
            <br />
            Solving the Silver division questions, I scored <b>900/1000</b> and
            got promoted to <b>Gold division</b>.
          </li>
        </ul>
      </p>
      <p>
        <b>University of Waterloo CEMC</b>
        <ul>
          <li>
            2018 Canadian Computing Contest (CCC Senior) - Placed in the{" "}
            <b>top 4%</b> of contestants after scoring 45/75 and making Group 4
            honour roll.
            <br />
            <br />
          </li>
          <li>
            Many years of CCC [WIP]
            <br />
            <br />
          </li>
          <li>
            Lots of CEMC math contests too [WIP]
            <br />
            <br />
          </li>
        </ul>
      </p>
      <p>
        In high school, I was also very involved in DECA, a business case
        competition, competing at the international level (ICDC) twice [WIP].
      </p>
      <hr />
      <p>
        Ever since I started working and learning how to adult, it's become more
        challenging to make time for these contests. I'm determined to stay
        involved in competitive programming in the next chapters of my life and
        ensure that this particular muscle doesn't atrophy.
      </p>
      <hr />
      <p>
        <i>Last updated October 2021.</i>
      </p>
    </div>
  );
};

export default AchievementsPage;
